html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Improve touch targets on mobile */
button, 
a, 
input, 
select, 
textarea {
  touch-action: manipulation;
}

/* Remove grey highlight when tapping links in iOS */
* {
  -webkit-tap-highlight-color: transparent;
}